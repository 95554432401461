var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"200px"}},[(this.getTileGrid().zoom > 0)?_c('div',{staticStyle:{"width":"200px","height":"200px","margin":"0px auto","background-color":"rgba(0, 0, 0, 0.2)","text-align":"center","line-height":"200px"}},[_c('canvas',{ref:"canvas",style:(_vm.getTileGrid().width > _vm.getTileGrid().height ?
      {
        verticalAlign: 'middle',
        width: '200px',
        height: `${200 * _vm.getTileGrid().height / _vm.getTileGrid().width}px`,
        imageRendering: 'pixelated',
      } : {
        verticalAlign: 'middle',
        width: `${200 * _vm.getTileGrid().width / _vm.getTileGrid().height}px`,
        height: '200px',
        imageRendering: 'pixelated',
      }),attrs:{"width":_vm.getTileGrid().width,"height":_vm.getTileGrid().height}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }